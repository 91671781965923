import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

//Store Language Context
import { useLanguage } from '../../store/LanguageContext';

//Language Files
import zhTextContent from '../../data/zh-TW/pageNotFound.json';
import enTextContent from '../../data/en-US/pageNotFound.json';

//Style
import './pagenotfound.style.css';

//Assets
import PageNotFoundIcon from '../../assets/pageNotFound.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/arrowLeft.svg';

interface PageNotFoundProps {
  // Define any additional props if needed
}

const PageNotFound: React.FC = () => {

  //Get selected language
  const { selectedLanguage } = useLanguage();
  const [textContent, setTextContent] = useState(selectedLanguage === 'ZH' ? zhTextContent : enTextContent);

  // Get latest language content whenever change
  useEffect(() => {
    setTextContent(prevTextContent => {
      return selectedLanguage === 'ZH' ? zhTextContent : enTextContent;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  return (
    <React.Fragment>
      <div className="content-margin">
        <div className="page-not-found-container">
          <h1 className="heading-1">{textContent?.heading_label}</h1>
          <img src={PageNotFoundIcon} alt="Page Not Found" />
          <NavLink to="/" className="go-home-container subtitle-2">
            <ArrowLeftIcon />
            <span>{textContent?.button_label}</span>
          </NavLink>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PageNotFound;