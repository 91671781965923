import React from 'react';
import { NavLink } from 'react-router-dom';

//Style
import './projectCard.style.css';

interface ProjectData {
    uid: string;
    order: number;
    protected: boolean;
    title: string;
    shortDesc: string;
    fullDesc: string;
    tags: string[];
    shortHeaderImagePath: string;
    headerImagePath: string;
    roles: string[];
    memberAndDate: string;
    projectSectionPaths: string[];
}

interface ProjectCardProps {
    projectDataItem: ProjectData;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ projectDataItem }) => {


    return (
        <React.Fragment>
            <div>
                <NavLink to={`/projects/${projectDataItem?.uid}`} className='project-card'>
                    <div className='project-image-area'>
                        <div className="project-image-link">
                            <img src={projectDataItem?.shortHeaderImagePath} alt='project header' className='project-short-header-image' />
                        </div>
                    </div>
                    <div className='project-text-area'>
                        <div className='title-area'>
                            <span className='body-font'>{projectDataItem?.tags.join('\u00A0•\u00A0')}</span>
                            {/* <span className='body-font'>{('product design\u00A0•\u00A0system design')}</span> */}
                            <h1 className='heading-2'>{projectDataItem?.title}</h1>
                        </div>
                        <div className='desc-area'>
                            <span className='body-font'>{projectDataItem?.shortDesc}</span>
                        </div>
                    </div>
                </NavLink>
            </div>
        </React.Fragment>
    );
};

export default ProjectCard;


