import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Project from '../Project/Project';
import PageNotFound from '../PageNotFound/PageNotFound';

//Language Files
import zhTextContent from '../../data/zh-TW/projectWrapper.json';
import enTextContent from '../../data/en-US/projectWrapper.json';

//Store Language Context
import { useLanguage } from '../../store/LanguageContext';

//Style
import './projectwrapper.style.css';

//Assets
import { ReactComponent as EyeOpenIcon } from '../../assets/eyeOpenIcon.svg';
import { ReactComponent as EyeClosedIcon } from '../../assets/eyeClosedIcon.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/arrowRight.svg';
import PasswordCardIcon1 from '../../assets/passwordCardIcon1.svg'

//Import the projects data
import zhProjectsData from "../../data/zh-TW/projectsData.json"
import enProjectsData from "../../data/en-US/projectsData.json"

interface UID {
  [key: string]: string | undefined;
}

interface ProjectData {
  uid: string;
  order: number;
  protected: boolean;
  title: string;
  shortDesc: string;
  fullDesc: string;
  tags: string[];
  shortHeaderImagePath: string;
  headerImagePath: string;
  roles: string[];
  memberAndDate: string;
  projectSectionPaths: string[];
}

interface ProjectsData {
  [key: string]: ProjectData;
}

const ProjectWrapper: React.FC = () => {

  // For protected projects
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [enteredPassword, setEnteredPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const correctPassword = process.env.REACT_APP_PROTECTED_PROJECTS_PASSWORD;

  const { uid } = useParams<UID>();
  const projectId: string = uid || 'projectNotExist';

  const zhProjects: ProjectsData = zhProjectsData;
  const enProjects: ProjectsData = enProjectsData;

  let projectFoundInZh = false;
  let projectFoundInEn = false;

  //Get selected language
  const { selectedLanguage } = useLanguage();

  const [textContent, setTextContent] = useState(selectedLanguage === 'ZH' ? zhTextContent : enTextContent);
  const [nextProjectData, setNextProjectData] = useState<ProjectData | null>(null);

  useEffect(() => {
    setTextContent(prevTextContent => {
      return selectedLanguage === 'ZH' ? zhTextContent : enTextContent;
    });

    // Convert the object into an array and sort it based on the order property
    const sortedProjects = Object.values(selectedLanguage === 'ZH' ? zhProjects : enProjects).sort((a, b) => a.order - b.order);

    setNextProjectData(getNextProjectItem(projectId, sortedProjects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, selectedLanguage]);

  function getNextProjectItem(currentUid: string, sortedProjects: ProjectData[]) {
    // Find the index of the current project in the sortedProjects array
    const currentIndex = sortedProjects.findIndex(project => project.uid === currentUid);

    // If the current project is found in the array
    if (currentIndex !== -1) {
      // Calculate the index of the next project
      const nextIndex = (currentIndex + 1) % sortedProjects.length;

      // Return the next project's item
      return sortedProjects[nextIndex];
    } else {
      // If the current project is not found, return null
      return null;
    }
  }


  // Check in zhProjects
  for (let project in zhProjects) {
    if (zhProjects[project].uid === projectId) {
      projectFoundInZh = true;
      break;
    }
  }

  // Check in enProjects
  for (let project in enProjects) {
    if (enProjects[project].uid === projectId) {
      projectFoundInEn = true;
      break;
    }
  }

  const handlePasswordChange = (event: any) => {
    setEnteredPassword(event.target.value);
  };

  const handlePasswordSubmit = () => {
    if (enteredPassword === correctPassword) {
      setShowPasswordError(false);
      setShowPasswordDialog(true);
    } else {
      setErrorMessage(textContent.errorMessage);
      setShowPasswordError(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handlePasswordSubmit();
    }
  };

  const renderProject = () => {
    if (zhProjects[projectId]?.protected || enProjects[projectId]?.protected) {
      return (
        <div className="password-container">
          <div className="password-card">
            <div className='wrapper-header-content'>
              <img src={PasswordCardIcon1} alt="password icon" />
              <div className='heading-1'>{textContent?.header}</div>
              <div className='upper-lower-text body-font'>
                <p>{textContent?.upperText}</p>
                <p>{textContent?.lowerText}</p>
              </div>
            </div>
            <div className='password-content'>
              <div className={showPasswordError ? "input-area error-border" : "input-area"} >
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder={textContent?.inputPlaceholder}
                  value={enteredPassword}
                  onChange={handlePasswordChange}
                  onKeyPress={handleKeyPress}
                  className={showPasswordError ? "password-input body-font" : "password-input body-font"}
                />
                <div className="show-password-container" onClick={toggleShowPassword}>
                  {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
                </div>
              </div>
              {showPasswordError ? <div><label className="error-text small-font">{errorMessage}</label></div> : ""}
            </div>
            <button onClick={handlePasswordSubmit} className="password-button">
              <span className='subtitle-2'>{textContent?.buttonLabel}</span>
            </button>
          </div>
        </div>
      );
    } else {
      return <Project />;
    }
  };

  // If project not found in either zhProjects or enProjects
  if (!projectFoundInZh || !projectFoundInEn) {
    return <PageNotFound />;
  } else {
    return (
      <React.Fragment>
        {showPasswordDialog ? <Project /> : renderProject()}
        <div className='next-project-container content-margin'>
          <div>
            <NavLink to={`/projects/${nextProjectData?.uid}`} className='next-project'>
              <div className='project-data'>
                <span className='body-font'>{nextProjectData?.tags.join('\u00A0\u00A0\u00A0•\u00A0\u00A0\u00A0')}</span>
                <h1 className='heading-2'>{nextProjectData?.title}</h1>
              </div>
              <div className='project-button subtitle-2'>
                <span>{textContent?.nextButtonLabel}</span>
                <ArrowRightIcon />
              </div>
            </NavLink>
          </div>
        </div>
      </React.Fragment>
    )
  }
};


export default ProjectWrapper;
