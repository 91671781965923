import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("ZH");
  // Create a ref. for the 'root' element
  const rootRef = useRef(null);

  // Get the device language
  useEffect(() => {
    // Get the primary language of the user's device
    const deviceLanguage = navigator.language || navigator.languages[0];

    setSelectedLanguage(deviceLanguage === "en-US" ? "EN" : "ZH");
  }, []);

  // Changing the root's font-family as per language selected.
  useEffect(() => {
    const rootElement = rootRef.current;
    // Update CSS variables based on selected language
    if (rootElement) {
      // Headings
      rootElement.style.setProperty(
        "--heading-font-family",
        selectedLanguage === "ZH"
          ? "'Noto Sans TC', 'Recoleta', sans-serif"
          : "'Recoleta', sans-serif"
      );

      // Primary Font
      rootElement.style.setProperty(
        "--primary-font-family",
        selectedLanguage === "ZH"
          ? "'Noto Sans TC', 'Poppins', sans-serif"
          : "'Poppins', sans-serif"
      );

      // Line Height for '.body-font'
      rootElement.style.setProperty(
        "--body-font-line-height",
        selectedLanguage === "ZH" ? "200%" : "160%"
      );
    }
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
      {/* {children} */}
      <div ref={rootRef}>{children}</div>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
