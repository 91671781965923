import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

//Language Files
import zhTextContent from '../../data/zh-TW/footer.json';
import enTextContent from '../../data/en-US/footer.json';

//Store Language Context
import { useLanguage } from '../../store/LanguageContext';

//Style
import './footer.style.css';

//Assets
import { ReactComponent as MediumIcon } from '../../assets/mediumIcon.svg'
import { ReactComponent as BehanceIcon } from '../../assets/behanceIcon.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/linkedInIcon.svg'



interface FooterProps {
    // Add if neeed
}

const Footer: React.FC<FooterProps> = () => {

    //Get selected language
    const { selectedLanguage } = useLanguage();
    const [textContent, setTextContent] = useState(selectedLanguage === 'ZH' ? zhTextContent : enTextContent);

    useEffect(() => {
        setTextContent(prevTextContent => {
            return selectedLanguage === 'ZH' ? zhTextContent : enTextContent;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLanguage]);


    return (
        <React.Fragment>
            <footer className="footer content-margin body-font">
                <div className="left-section">
                    {textContent.left_label}
                </div>
                <div className="socials">
                    <NavLink to="https://medium.com/@s45673hil07" target="_blank" rel="noopener noreferrer" className="social-link">
                        <MediumIcon />
                    </NavLink>
                    <NavLink to="https://behance.net/70ea1e7a" target="_blank" rel="noopener noreferrer" className="social-link">
                        <BehanceIcon />
                    </NavLink>
                    <NavLink to="https://www.linkedin.com/in/yuna-lin/" target="_blank" rel="noopener noreferrer" className="social-link">
                        <LinkedInIcon />
                    </NavLink>
                </div>
                <div className="right-section">
                    <NavLink to={`mailto:${textContent.email.address}`} target="_blank" rel="noopener noreferrer" className="email-address">
                        <p className="email-address"> {textContent.email.label} </p>
                    </NavLink>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;


