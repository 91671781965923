import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

// React Router
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from "react-router-dom";

//Store Language Context
import { LanguageProvider } from './store/LanguageContext'; // Import LanguageProvider

// Routes
import Home from "./routes/Home/Home";
import ProjectWrapper from './routes/ProjectWrapper/ProjectWrapper';
import PageNotFound from "./routes/PageNotFound/PageNotFound";

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

//Style
import './styles/global.style.css';

const AppLayout = () => (
  <React.Fragment>
    <Navbar />
    <Outlet />
    <Footer />
  </React.Fragment>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
};


const router = createBrowserRouter([
  {
    element: (
      <LanguageProvider>
        <ScrollToTop />
        <AppLayout />
      </LanguageProvider>
    ),
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />
      },
      {
        path: "/projects/:uid",
        element: <ProjectWrapper />,
      },
      {
        path: "*",
        element: <PageNotFound />
      },
    ]
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);